div.header {
    width: 100vw;
    display: flex;
    justify-content: space-around;
}
.navlink {
    padding: 5px 10px;
    border-radius: 4px;
    color: #000;
    font-weight: 500;
}

.navlink:hover {
    background-color: grey;
    color: white;
}
.active {
    border-radius: 4px 4px 0 0;
    border-bottom: 2px solid grey;
}

.active:hover {
    background-color: rgba(0, 0, 0, 0);
    color: #000;
    cursor: default;
}