p.title {
    text-align: center;
}

div.wrapper {
    display: flex;
}

h4 {
    padding: 0 0 0 8px;
}

div p {
    margin: 10px;
}
select {
    width: 13vw;
    display: flex;
    margin: 10px 0.5vw;
}
div.save-plan {
    display: flex;
    justify-content: center;
}
.save-plan input {
    width: 250px;
    margin: 0 20px;
}
.save-plan button {
    margin: 0 20px;
}
div.summary {
    display: flex;
    justify-content: center;

}