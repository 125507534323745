div.recipe-summary {
    width: 65vw;
    font-size: .8em;
    margin: 0 10px;
    border: 1px solid gray;
    border-radius: 5px;
    display: flex;
    padding: 0 10px 15px;
    background-color: #fff;
}

.left-panel {
    /*border: 1px solid red;*/
    width: 50%;
}

.right-panel {
    /*border: 1px solid green;*/
    width: 50%;
}

.column-title {
    border-bottom: 1px solid #000;
}

ul {
    margin-left: 20px;
}

span {
    font-style: italic;
    font-weight: 900;
}

div.recipe-summary:hover {
    box-shadow: 1px 1px 5px grey;
}
