div.week-summary {
    width: 25vw;
    font-size: .8em;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
    background-color: #fff;
}

div.week-summary:hover {
    box-shadow: 1px 1px 5px grey;
}

.tableCell {
    /*border: 1px dotted grey;*/
}
table {
    width: 100%;
    border-collapse: collapse;
}
th {
    text-align: left;
    padding: 0 20px 5px;
}

tbody tr:nth-child(odd) {
    background-color: #eeeded;
}

tbody tr td {
    padding: 3px 0 3px 20px;
}
