.confirm-window {
    position: absolute;
    left: 40vw;
    top: 35vh;
    width: 200px;
    text-align: center;
    padding: 5px;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 3px;
    color: white;
    font-size: 1.5em;
    border: 1px solid black;
}